/* global instantsearch */

import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import _ from 'underscore';

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: 'MySuperstrongpassword#6876876578', // Be sure to use an API key that only allows searches, in production
    nodes: [
      {
        host: 'typesense.dev3.digitalservices.syr.edu',
        port: '443',
        protocol: 'https',
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  //  filterBy is managed and overridden by InstantSearch.js. To set it, you want to use one of the filter widgets like refinementList or use the `configure` widget.
  additionalSearchParameters: {
    queryBy: 'title, excerpt, content',
  },
  // Search parameters that need to be *overridden* on a per-collection-basis go here:
  // collectionSpecificSearchParameters: {
  //   news: {
  //     queryBy: 'title, excerpt, content',
  //   },
  //   calendar: {
  //     queryBy: "title",
  //   },
  // },
});

const searchClient = typesenseInstantsearchAdapter.searchClient;

const search = instantsearch({
  searchClient,
  indexName: 'documents',
});


search.addWidgets([
  instantsearch.widgets.searchBox({
    container: '#searchbox',
    showSubmit: false,
  }),
  instantsearch.widgets.configure({
    hitsPerPage: 8,
    default: 0,
  }),
  instantsearch.widgets.hits({
    container: '#hits',
    cssClasses: {
      root: 'MyCustomHits',
      item: ['margin-0']
    },
    templates: {
      item(item) {
        let title = `<a href="${item.url}">${_.unescape(item._highlightResult.title.value)}</a>`;
        if (item.degree){
          title = `<a href="${item.url}">${_.unescape(item._highlightResult.degree.value)} - ${_.unescape(item._highlightResult.title.value)}</a>`;
        }
        
        return `
          <div class='card'>
            <div class='card-header'>
              <h3 class='result-heading'>${title}</h3>
            </div>
            <div class='card-body'>
              ${_.unescape(item._highlightResult.excerpt.value)}
            </div>
          </div>
      `;
      },
    },
  }),
  instantsearch.widgets.stats({
    container: '#stats',
  }),
  instantsearch.widgets.refinementList({
    container: '#refinement-list',
    attribute: 'document_type',
    sortBy: ['name:asc'],
  }),
  instantsearch.widgets.pagination({
    container: '#pagination',
  }),
]);

search.start();

